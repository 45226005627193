<template>
  <b-card title="Listar Vendedores">
    <listarVendedores />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarVendedores from '../../Listagens/Vendedores/ListarVendedores.vue'

export default {
  components: {
    BCard, listarVendedores,
  },

}
</script>
